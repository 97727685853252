.AboveTheFold {
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: row;
  overflow: "hidden";
}

.AboveTheFoldContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: "hidden";
  align-items: center;
  justify-content: center;
}

.AboveTheFoldImage {
  display: flex;
  flex: 1;
  overflow: "hidden";
  align-items: center;
  justify-content: center;
}
.AboveTheFoldImage img {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .AboveTheFold {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .AboveTheFoldImage {
    flex-direction: column-reverse;
    padding-top: 8vh;
    align-items: flex-start;
    justify-content: flex-start;
    min-height: 35vh;
    flex: 0;
  }
  .AboveTheFoldContent {
    padding-top: 5vh;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 0;
  }
}

.MainText {
  position: relative;
  background: white;
  width: max-content;
  padding-right: 20px;
  font-size: 5vw;
  margin: 1vw;
  padding: 0.5vw;
  padding-left: 1vw;
}

@media only screen and (max-width: 768px) {
  .MainText {
    font-size: 12vw;
  }
}

.MainText::selection {
  background: black;
  color: white;
}

.MainTextEm {
  font-style: italic;
}

.InnerMainText {
  opacity: 0;
  display: inline;
  font-size: 2vw;
}

.InnerMainEmoji {
  display: inline;
  font-size: 1.25vw;
}

.InnerMainLastDot {
  opacity: 0;
  display: inline;
  font-size: 5vw;
}

.InnerMainEmoji::selection {
  background: black;
  color: white;
}

.EmojiRocketOuter {
  display: inline;
  position: absolute;
  right: 4vw;
}

.EmojiRocket {
  display: inline;
  font-size: 5vw;
  position: relative;
}

@media only screen and (max-width: 768px) {
  .InnerMainText {
    opacity: 0;
    display: inline;
    font-size: 3.5vw;
  }

  .InnerMainEmoji {
    display: inline;
    font-size: 3vw;
  }

  .InnerMainLastDot {
    opacity: 0;
    display: inline;
    font-size: 12vw;
  }

  .EmojiRocketOuter {
    display: inline;
    position: absolute;
    right: 6vw;
    transform: rotate(-45deg);
  }

  .EmojiRocket {
    font-size: 10vw;
  }
}

.ScaleWrapper {
  position: absolute;
  display: inline;
  top: 0;
  left: 0;
  width: 100px;
  overflow: hidden;
  height: 12vw;
}

.ScaleWrapper {
  position: absolute;
  display: inline;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 80vw;
  height: 100px;
}

.Launch {
  animation: launch 3s 1 ease-in-out;
  animation-fill-mode: forwards;
}

.MainText.Scale {
  position: absolute;
  top: -1vw;
  left: 0px;
  padding-right: 0px;
  transform: translate3d(-32vw, 0px, 0);
}

.MainText.Scale.AnimateIn {
  animation: scalepop 1s 1 ease;
  animation-fill-mode: forwards;
}

@media only screen and (max-width: 768px) {
  .MainText.Scale {
    transform: translate3d(-60vw, 0px, 0);
  }
}

@keyframes scalepop {
  0% {
    transform: translate3d(-32vw, 0px, 0);
  }
  100% {
    transform: translate3d(0vw, 0px, 0);
  }
}

@media only screen and (max-width: 768px) {
  @keyframes scalepop {
    0% {
      transform: translate3d(-64vw, 0px, 0);
    }
    100% {
      transform: translate3d(0vw, 0px, 0);
    }
  }
}

@keyframes launch {
  0% {
    transform: translate3d(0px, 0px, 0);
  }
  100% {
    transform: translate3d(1200px, -1200px, 0);
  }
}

@media only screen and (max-width: 768px) {
  @keyframes launch {
    0% {
      transform: rotate(-45deg) translate3d(0px, 0px, 0);
    }
    100% {
      transform: rotate(-45deg) translate3d(1200px, -1200px, 0);
    }
  }
}
