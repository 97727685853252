.Section {
  display: flex;
  width: calc(100vw - 12vmax);
  height: calc(100vh - 12vmax);
  padding: 6vmax;
  background-color: white;
}
.Section.Banner {
  background-color: #5299dc;
}

@media only screen and (max-width: 768px) {
  .Section {
    padding: 1vmax;
    width: calc(100vw - 2vmax);
    height: auto;
  }
  .Section.Banner {
    padding-bottom: 8vh;
  }
  .Section.Child {
    padding: 1vmax;
    margin-bottom: 2vh;
    width: calc(100vw - 2vmax);
    height: auto;
  }
}

.SectionContent {
  flex: 1;
  display: flex;
}
.SectionContent.Left {
  flex-direction: row-reverse;
}
.SectionContent.Right {
  flex-direction: row;
}
.SectionContentChild {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 3vw;
  width: 80%;
  justify-content: center;
  align-items: center;
}

.SectionContentWrapper {
  width: 100%;
}
.SectionContentWrapper div {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  .SectionContent.Left {
    flex-direction: column;
  }
  .SectionContent.Right {
    flex-direction: column;
  }

  .SectionContentChild {
    width: calc(100% - 6vw);
    padding: 3vw;
  }
}
