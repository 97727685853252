@font-face {
  font-family: "Playfair Display";
  src: local("Playfair Display")
    url(./fonts/PlayfairDisplay-VariableFont_wght.ttf) format("truetype");
}

video::-webkit-media-controls-panel {
  background-color: transparent !important;
  box-shadow: none !important;
}

.App {
  font-family: "Playfair Display", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h2 {
  font-weight: 500;
  font-size: 18;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  h2 {
    font-weight: 500;
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
